import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"

const PageTemplate = ({ data }) => {
  const content = data.strapiPage
  const blocks = content.blocks

  return (
    <Layout as="article">
      <Seo seo={{ ...content.defaultSeo, url: content.slug }} />

      <BaseBlocksRenderer blocks={blocks} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiPage(slug: { eq: $slug }) {
      id
      slug
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          id
          url
          localFile {
            url
          }
        }
      }
      blocks {
        __typename
        ...BlockQuote
        ...BlockHeroSection
        ...BaseSectionTitle
        ...BlockImageSupport
        ...BlockResponsiveMedia
        # ...BaseIframeSource
        ...BlockSlider
        ...BaseCTA
        ...BlockVideoEmbed
        ...BlockLegalBase
        ...BlockPromoBox
        ... on STRAPI__COMPONENT_SHARED_HEADER {
          headerTitle: title
          cols
          isLarge
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
          richTextBody: body {
            data {
              id
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SHARED_PRICE_TEST {
          columns
          priceSectionTitle {
            ...BaseSectionTitle
          }
          pricePick {
            voltio_price {
              ...BasePriceCard
            }
            landing_price {
              id
              type
              order
              title
              price
              subtitle
              rate: perTime
              featured
              clarification {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              highlightColor
              body {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
